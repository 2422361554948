import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout.js";

import HeroSection from "../components/HeroSection.js";
import Section from "../components/Section";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";

import { BackgroundImageDivResponsive } from "../components/BackgroundImage.js";
import FlexGroup from "../components/utilities/FlexGroup.js";
import BenefitsList from "../components/BenefitsList.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";
import Famous from "../components/Famous.js";
import Introduction from "../components/Introduction.js";
import IconSelect from "../components/utilities/IconSelect.js";
import CountdownTimer from "../components/CountdownTimer";
import BrandButton from "../components/BrandButton.js";
import PricingMembership from "../components/PricingMembership.js";
import Overview from "../components/Overview.js";
import ContentsList from "../components/ContentsList";
import { BackgroundImageDiv } from "../components/BackgroundImage";
import FAQs from "../components/FAQs";
import Video from "../components/Video.js";
import Steps from "../components/Steps.js";

export const SalesPageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      {/*------------------------- START MAIN CONTENT -------------------------*/}
      <div>
        {/*------------------------- FIRST ELEMENT REFACTORED - heroObject -------------------------*/}
        <HeroSection isSectionComponent={false}>
          <BackgroundImageDivResponsive
            imageObject={data.heroObject.imageObject}
            className="h-full"
          >
            {/* wrapper div to add padding */}
            <div className="py-3 sm:py-10 h-full lg:flex lg:flex-col lg:justify-center">
              <div className="flex flex-row flex-wrap justify-around xl:justify-start content-between items-stretch box-border lg:mx-10 xl:mx-0">
                <article className="w-1/2 flex flex-shrink" />
                <article
                  className={`bg-white bg-opacity-75 p-6 rounded-xl w-11/12 max-w-xl`}
                >
                  <RenderMarkdown
                    markdownContent={data.heroObject.title}
                    whitespace="normal"
                    className="pb-2 sm:pb-5"
                  />
                  <RenderMarkdown
                    markdownContent={data.heroObject.subtitle}
                    whitespace="normal"
                    className="pb-2 sm:pb-5"
                  />

                  <CountdownTimer
                    className="m-2 xl:m-5"
                    countdownTitle={data.heroObject.countdownTitle}
                    date={data.heroObject.date}
                  />
                  <div className="flex justify-center">
                    <BrandButton>
                      <RenderMarkdown
                        markdownContent={data.heroObject.cta}
                        isMarkdown={false}
                        whitespace="normal"
                      />
                    </BrandButton>
                  </div>
                </article>
              </div>
            </div>
          </BackgroundImageDivResponsive>
        </HeroSection>

        {/*------------------------- SECOND ELEMENT - benefitsObject -------------------------*/}

        <Section className="flex flex-col items-center">
          <BenefitsList benefitsObject={data.benefitsObject} />
        </Section>

        {/*------------------------- Fourth ELEMENT - testimonalsTeaser -------------------------*/}
        <Section className="bg-brand-beige-40">
          <ContentsList
            contentsObject={data.contentsObject}
            classNameCard="bg-white"
          />
        </Section>

        {/*------------------------- THIRD ELEMENT - statsObject -------------------------*/}
        <Section className="bg-brand-mint py-10">
          <RenderMarkdown
            markdownContent={data.statsObject.title}
            className="text-center"
          />

          <FlexGroup
            ElementName={StatsAndLabelCMS}
            list={data.statsObject.statisList}
          />
        </Section>

        {/*------------------------- Fourth ELEMENT - introductionObject -------------------------*/}
        <Section className="bg-brand-beige-40">
          <Introduction introductionObject={data.introductionObject} />
        </Section>

        {/*------------------------- FIFTH ELEMENT - famousObject -------------------------*/}
        <Section className="bg-brand-mint">
          <Famous famousObject={data.famousObject} />
        </Section>

        {/*------------------------- SEVENTH ELEMENT - featuresObject -------------------------*/}
        <Section>
          <RenderMarkdown
            markdownContent={data.featuresObject.title}
            className="text-center"
          />
          <FlexGroup
            ElementName={FeaturesElement}
            list={data.featuresObject.featuresList}
          />
          <div className="flex justify-center">
            <BrandButton>
              <RenderMarkdown
                markdownContent={data.featuresObject.cta}
                isMarkdown={false}
                whitespace="normal"
              />
            </BrandButton>
          </div>
        </Section>

        {/*------------------------- stepsObject -------------------------*/}

        <Section className="bg-brand-beige-40">
          <Steps stepsObject={data.stepsObject} />
        </Section>

        {/*------------------------- EIGHTH ELEMENT - reviewsObject -------------------------*/}
        <Section
          className="bg-brand-mint"
          classNameInnerContainer="space-y-8 sm:space-y-14"
        >
          <RenderMarkdown
            markdownContent={data.testimonialsObject.title}
            className="text-center"
          />
          <Video videoObject={data.testimonialsObject.video} />
          <div className="flex justify-center">
            <BrandButton
              href={data.testimonialsObject.ctaObject.linkUrl}
              className=""
            >
              <RenderMarkdown
                markdownContent={data.testimonialsObject.ctaObject.linkText}
                whitespace="normal"
              />
            </BrandButton>
          </div>
          {data.testimonialsObject.contentsObjectList.map((element, index) => (
            <ContentsList key={index} contentsObject={element} />
          ))}
        </Section>

        {/*------------------------- TENTH ELEMENT - pricingObject -------------------------*/}
        <Section>
          <PricingMembership
            pricingMembershipObject={data.pricingMembershipObject}
            isAlwaysOffer={true}
          />
        </Section>
        <Section>
          <Overview
            overviewObject={data.refundPolicyObject}
            whitespace="pre-line"
          />
          <div className="flex justify-center">
            <PreviewCompatibleImage
              imageObject={data.refundPolicyObject.signatureImageObject}
              className="max-w-xs"
            />
          </div>
        </Section>

        {/*------------------------- XTH ELEMENT - faqsObject -------------------------*/}

        <Section className="bg-brand-mint">
          <FAQs faqsObject={data.faqsObject} />
        </Section>

        {/*------------------------- LAST ELEMENT - faqsObject -------------------------*/}
        <BackgroundImageDiv
          imageObject={data.finalCtaObject.imageObject}
          className="w-full h-full"
        >
          <Section className="">
            <div className="my-40 space-y-10">
              <article className="justify-center p-5 sm:p-8 bg-white bg-opacity-70 rounded-xl max-w-lg mx-auto">
                <RenderMarkdown
                  markdownContent={data.finalCtaObject.text}
                  className="text-center"
                />
                <div className="flex justify-center">
                  <BrandButton href={data.finalCtaObject.ctaObject.linkUrl}>
                    <RenderMarkdown
                      markdownContent={data.finalCtaObject.ctaObject.linkText}
                      whitespace="normal"
                    />
                  </BrandButton>
                </div>
              </article>
            </div>
          </Section>
        </BackgroundImageDiv>
      </div>
    </Layout>
  );
};

// ------------------------- Components & Functions -------------------------
function StatsAndLabelCMS({ number, description }) {
  return (
    <article className="flex flex-col items-center w-full px-5 pt-5 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 font-normal">
      <p className="text-5xl">{number.toLocaleString()}</p>
      <RenderMarkdown
        markdownContent={description}
        whitespace="normal"
        className="text-center pt-5"
      />
    </article>
  );
}

function FeaturesElement({ imageObject, description }) {
  return (
    <article className="flex flex-col items-center text-center p-0 sm:p-5 w-full sm:w-1/2 md:w-1/3 xl:w-1/3">
      <div className="h-40 md:h-48 lg:h-60 flex flex-col justify-center mb-3">
        <PreviewCompatibleImage
          imageObject={imageObject}
          className="rounded-3xl max-h-full max-w-xs sm:max-w-none"
        />
      </div>
      <RenderMarkdown markdownContent={description} isMarkdown={true} />
    </article>
  );
}

function IconAndLabelCMS({ iconName, title }) {
  return (
    <div className="flex flex-col items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6">
      <IconSelect iconName={iconName} className="h-20" />
      <div className="h-24 sm:h-20">
        <RenderMarkdown
          markdownContent={title}
          className="pt-4 text-center"
        ></RenderMarkdown>
      </div>
    </div>
  );
}

const SalesPage = ({ data }) => {
  return <SalesPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default SalesPage;

export const pageQuery = graphql`
  query SalesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          cta
          countdownTitle
          date
          subtitle
          title
        }
        benefitsObject {
          list {
            description
            iconName
          }
          title
        }
        citationObject {
          citation
        }
        contentsObject {
          title
          ctaObject {
            linkUrl
            linkText
          }
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        famousObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            mobileImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          signatureImageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        testimonialsObject {
          title
          video {
            cloudflareId
          }
          ctaObject {
            linkUrl
            linkText
          }
          contentsObjectList {
            title
            list {
              imageObject {
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
                alt
              }
              description
            }
            ctaObject {
              linkUrl
              linkText
            }
          }
        }
        signupObject {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        statsObject {
          title
          statisList {
            number
            description
          }
        }
        featuresObject {
          title
          cta
          featuresList {
            description
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        stepsObject {
          steps {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
            video {
              cloudflareId
              format
            }
          }
        }
        pricingMembershipObject {
          anchor
          title
          codeOffer
          titleOffer
          titleClosed
          regularPricePerMonth
          pricingCardObjectMonthly {
            title
            priceText
            priceHint
            ctaObject {
              linkUrl
              linkText
            }
            priceOffer
            priceOfferText
            ctaTextClosed
          }
          pricingCardObjectYearly {
            overline
            title
            priceAdditive
            priceText
            priceHint
            ctaObject {
              linkUrl
              linkText
            }
            priceOffer
            priceOfferText
            ctaTextClosed
          }
          reducedOfferObject {
            linkText
            linkUrl
          }
        }
        refundPolicyObject {
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          description
          signatureImageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        finalCtaObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
            alt
          }
          text
          ctaObject {
            linkText
            linkUrl
          }
        }
      }
    }
  }
`;
